<template>
<main>
  <section class="section">
    <div>
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation_address', relation)}}</sqr-crumb>
        </ul>
      </nav>
    </div>
  </section>

  <sqr-error :error="loadError"/>

  <section class="section" v-if="!loading && !loadError">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-4">
            <div class="field">
              <label class="label">{{$t('relation_address_search')}}</label>
              <input ref="search" type="search" class="input" :placeholder="$t('relation_address_search')">
            </div>
            <div v-if="address">
            <div class="columns">
              <div class="column is-9">
                <div class="field">
                  <sqr-input-text
                    label="relation_address_street"
                    :value="address.streetAddress"
                    @change="fieldSet({group: 'address', field: 'streetAddress', value: $event})"
                  />
                </div>
              </div>
              <div class="column is-3">
                <div class="field">
                  <sqr-input-text
                    label="relation_address_street_number"
                    :value="address.streetNumber"
                    @change="fieldSet({group: 'address', field: 'streetNumber', value: $event})"
                  />
                </div>
              </div>
            </div>
            <div class="field">
              <sqr-input-text
                label="relation_address_post_office_box_number"
                :value="address.postOfficeBoxNumber"
                @change="fieldSet({group: 'address', field: 'postOfficeBoxNumber', value: $event})"
              />
            </div>
            <div class="columns">
              <div class="column is-3">
                <div class="field">
                  <sqr-input-text
                    label="relation_address_postal_code"
                    :value="address.postalCode"
                    @change="fieldSet({group: 'address', field: 'postalCode', value: $event})"
                    />
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <sqr-input-text
                    label="relation_address_locality"
                    :value="address.addressLocality"
                    @change="fieldSet({group: 'address', field: 'addressLocality', value: $event})"
                  />
                </div>
              </div>
              <div class="column is-3">
                <div class="field">
                  <sqr-input-text
                    label="relation_address_region"
                    :value="address.addressRegion"
                    @change="fieldSet({group: 'address', field: 'addressRegion', value: $event})"
                  />
                </div>
              </div>
            </div>
            <div class="field">
              <sqr-input-text
                label="relation_address_country"
                :value="address.addressCountry"
                @change="fieldSet({group: 'address', field: 'addressCountry', value: $event})"
              />
            </div>
            <div class="field">
              <div class="buttons is-pulled-right">
                <sqr-button label="buttons_cancel" @click="$router.back()" color="light" size="medium"/>
                <sqr-button label="buttons_save" @click="saveAndGo()" color="primary" size="medium" :is-loading="saving"/>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  </section>
</main>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';

import relation from './relation';

import SqrButton from '../sqrd/SqrButton';
import SqrInputText from '../sqrd/SqrInputText';

let autocomplete;

export default {
  name: 'RelationAddress',
  mixins: [relation],
  components: {SqrInputText},
  props: {
    bid: String,
    rid: String
  },
  computed: {
    ...mapGetters('relation', ['loading', 'saving', 'loadError']),
    address () {
      return this.relation && this.relation.address || {};
    },
    title () {
      if (!this.relation) return '';
      return this.$t('relation_title', Object.assign({}, this.relation, {
        status: this.$t('relation_status_' + this.relation.status)
      }));
    },
    subtitle () {
      if (!this.relation || !this.relation.birthDate) return '';
      return this.$t('relation_subtitle', {birthDate: date(this.relation.birthDate)});
    }
  },
  mounted () {
    this.sub({path: ['brokers', this.bid], id: this.rid});
    autocomplete = new google.maps.places.Autocomplete(this.$refs.search);
    // Set the data fields to return when the user selects a place.
    autocomplete.setFields(['address_components']);

    console.log(autocomplete);

    var types = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
    };

    var fields = {
      street_number: 'streetNumber',
      route: 'streetAddress',
      locality: 'addressLocality',
      administrative_area_level_1: 'addressRegion',
      postal_code: 'postalCode',
      country: 'addressCountry',
    }

    let self = this;
    autocomplete.addListener('place_changed', function() {
      var place = autocomplete.getPlace();

      for (var i = 0; i < place.address_components.length; i++) {
        let x = place.address_components[i].types[0];
        let type = types[x];
        let field = fields[x];
        if (type && field) {
          let value = place.address_components[i][type];
          self.fieldSet({group: 'address', field, value});
        }
      }
    });
  },
  methods: {
    ...mapMutations('relation', ['fieldSet']),
    ...mapActions('relation', ['sub', 'save']),
    saveAndGo () {
      this.save().then(() => this.$router.back());
    }
  },
  metaInfo () {
    return {
      title: this.title
    }
  }
}
</script>

