import { mapGetters } from 'vuex';

export default {
  props: {
    bid: String
  },
  computed: {
    ...mapGetters('brokers', ['broker'])
  }
};
