import {mapGetters, mapActions} from 'vuex';
import broker from './broker.js';

export default {
  props: {
    rid: String,
  },
  mixins: [broker],
  computed: {
    ...mapGetters('relation', {relation: 'record', relLoading: 'loading'}),
  },
  mounted () {
    if (this.rid) {
      console.log('mixin relation mounted', this.bid, this.rid);
      this.relSub({
        path: ['brokers', this.bid],
        id: this.rid
      });
    }
  },
  methods: {
    ...mapActions('relation', {relSub: 'sub'}),
  }
}
